<template>
  <Toolbar class="mb-4">
    <template #left>
      <label class="p-field _base-search-size">
        <div class="p-input-icon-right">
          <InputText type="search" v-model="search" :placeholder="$t('search')" />
          <Icon name="zoom" className="p-field-icon" />
        </div>
      </label>
    </template>

    <template #right>
      <router-link :to="{ name: 'CreatingEmployee' }" class="p-button mb-4  mt-5">
        <Icon name="plus" className="" />
        <span v-t="'company_page.add_new_employee'" />
      </router-link>
    </template>
  </Toolbar>
  <div class="_relative">
    <Loader isOverlay v-if="state.isLoading" />
    <DataTable :value="state.data" :loading="state.isLoading" responsiveLayout="scroll">
      <template #empty>
        <Empty />
      </template>
      <Column header="id">
        <template #body="slotProps">{{slotProps.data.id}}</template>
      </Column>
      <Column header="name">
        <template #body="slotProps"><router-link class="_link" :to="{ name: 'ProfileStaffPerson', params: { userID: slotProps.data.id } }">{{slotProps.data.name}}</router-link></template>
      </Column>
      <Column header="email">
        <template #body="slotProps">{{slotProps.data.email}}</template>
      </Column>
      <Column header="phone">
        <template #body="slotProps">{{slotProps.data.phone}}</template>
      </Column>
      <Column header="credits">
        <template #body="slotProps">{{slotProps.data.credits}}</template>
      </Column>
      <Column header="status">
        <template #body="slotProps"><StatusBool :isActive="slotProps.data.is_active" /></template>
      </Column>
    </DataTable>
  </div>


  <Paginator
    :currentPage="state.meta.current_page || $route.query.page || 1"
    :total="state.meta.total"
    :lastPage="state.meta.last_page"
    :perPage="state.meta.perPage"
    :count="state.meta.to"
  />
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { Icon, Empty, Loader, StatusBool } from '../../../components/ui'
import { Paginator } from '../../../components/common'

export default {
  components: {
    Toolbar,
    InputText,
    Icon,
    DataTable,
    Column,
    Loader,
    Empty,
    StatusBool,
    Paginator,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const search = ref()

    store.dispatch('companyUsers/reqBase', { per_page: 15, page: route.query.page || 1, search: search.value })
    const state = store.state.companyUsers
    watch(route, () => {
      store.dispatch('companyUsers/reqBase', { per_page: 15, page: route.query.page || 1, search: search.value })
    })

    watch(search, (newValue) => {
      // const query = {...route.query, ...{ search: newValue }}
      router.push({ name: route.name, query: { page: 1, search: newValue } })
    })

    return {
      search,
      state,
    }
  }
}
</script>
